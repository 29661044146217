import { FormattedMessage } from 'react-intl';

export default function FooterInfomation() {
  return (
    <div className='footer-info'>
      <div className='mb-4 leading-8'>
        <p className='pb-4 text-center sm:text-left'>
          <FormattedMessage
            id='app.common.company_intro1'
            defaultMessage='Beccato Global Sdn. Bhd. is a company which committed to caring and sharing ‘Healthy’ with every single one of you.'
          />
        </p>

        <p className='pb-4 text-center sm:text-left'>
          <FormattedMessage
            id='app.common.company_intro2'
            defaultMessage='Beccato strived to be YOUR trusted health brand. 😉'
          />
        </p>

        <p className='pb-4 text-center font-bold'>
          <FormattedMessage id='app.common.company_intro4' defaultMessage='’EAT GOOD. LOOK GOOD. FEEL GOOD.’' />
        </p>
      </div>
    </div>
  );
}
