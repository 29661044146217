export const About = [
  { title: 'About us', href: '/about-us', id: 'app.common.about_us' },
  { title: 'Contact', href: '/contact-us', id: 'app.common.contact_us' },
  { title: 'Blogs', href: '/blogs', id: 'app.common.blogs' },
];

export const Helps = [
  { title: 'How To Order', href: '/how-to-order', id: 'app.common.how_to_order' },
  { title: 'Payment Methods', href: '/payment-methods', id: 'app.common.payment_methods' },
  { title: 'Shipping Information', href: '/shipping-information', id: 'app.common.shipping_information' },
  { title: 'FAQ', href: '/faq', id: 'app.common.faq' },
];
