/**
 *
 * @description Layout.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 30 October 2021
 *
 */

import Head from 'next/head';
import React, { ReactNode } from 'react';

import Footer from '~/components/footer/Footer';
import MenuOne from '../header/MenuOne';

const Menu = () => {
  return (
    <div className='fixed inset-x-0 z-10 bg-white'>
      <MenuOne />
    </div>
  );
};

type Props = {
  title: string;
  children?: ReactNode | undefined;
  footerClass?: string | undefined;
  head?: ReactNode | undefined;
};
export default function Layout({ title, children, head }: Props) {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='robots' content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' />
        <meta property='og:locale' content='cn' />
        <meta property='og:site_name' content='Beccato Global' />
        {head}
      </Head>
      <Menu />

      <div className='pt-32 sm:pt-48'>{children}</div>
      <Footer />
    </>
  );
}
